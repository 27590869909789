$(function () {
  // 父轮播
  let parentSwiper = new Swiper('.parent-container', {
    direction: 'vertical',
    loop: false,
    speed: 1200,
    mousewheel: true,
    simulateTouch: false,
    pagination: {
      el: '.parent-pagination',
      clickable: true
    },
    on: {
      init: function () {
        setTimeout(() => {
          var bullet = this.pagination.bullets[0]
          bullet.innerHTML = '01'
          parentSwiper.mousewheel.enable();
          $('#header .dropdown-mask').removeClass('d-block');
          console.log('init setTimeout');
        }, 100)

      },
      // 开始切换
      transitionStart: function () {
        let swiperPagination = document.querySelector('.parent-pagination')
        let bullets = swiperPagination.querySelectorAll('.swiper-pagination-bullet')
        for (let index = 0; index < bullets.length; index++) {
          let bullet = bullets[index];
          if (index === this.activeIndex) {
            bullet.innerHTML = `0${index + 1}`
          } else {
            bullet.innerHTML = '·'
          }
        }
      },
      // 开始切换
      slideChangeTransitionStart: function () {
        switch (this.activeIndex) {
          case 1:
          case 5:
            $('#header .nav').addClass('bg')
            break;
          default:
            $('#header .nav').removeClass('bg')
            break;
        }
      },
    }

  })

  // 子轮播
  let childSwiper = new Swiper('.child-container', {
    loop: true,
    speed: 800,
    // autoplay: true,
    autoplay: {
      delay: 7000,
    },
    nested: true,
    grabCursor: true,
    pagination: {
      el: '.child-pagination',
      clickable: true
    }
  })

  /**
   * 第三屏
   */
  var swiper3 = new Swiper('.step-3 .swiper-container', {
    spaceBetween: 40,
    autoplay: {
      delay: 7000,
    },
    navigation: {
      nextEl: '.step-3 .button-next',
      prevEl: '.step-3 .button-prev',
    },
    // 事件
    on: {
      // 开始切换
      slideChangeTransitionStart: function () {
        let index = this.activeIndex;
        let items = $('.step-3 .pagination .item');
        items.eq(index).addClass('active').siblings().removeClass('active');
      },
      // 结束切换
      slideChangeTransitionEnd: function () {
        swiperAnimate(this);
      },
      // 初始化
      init: function () {
        swiperAnimateCache(this); //隐藏动画元素
        this.emit('slideChangeTransitionEnd'); //在初始化时触发一次slideChangeTransitionEnd事件

      },
    },
  });

  $('.step-3 .pagination .item').on('click', function () {
    let index = $('.step-3 .pagination .item').index($(this));
    swiper3.slideTo(index);
  })


  // 步骤5
  var swiper5 = new Swiper(".step-5 .swiper-container", {
    slidesPerView: "auto",
    spaceBetween: 24,
    freeMode: true,
    scrollbar: {
      el: ".step-5 .swiper-scrollbar",
    },
  });

  // 鼠标移入
  $("#header .nav-item").on('mouseenter', function () {
    $(this).addClass('down');
    if ($(this).hasClass('down')) {
      $('#header .dropdown-mask').addClass('d-block');
    } else {
      $('#header .dropdown-mask').removeClass('d-block');
    }
  })
  // 鼠标移出
  $("#header .nav-item").on('mouseleave', function () {
    $(this).removeClass('down');
    $('#header .dropdown-mask').removeClass('d-block');
  })

  $('#header .dropdown-mask').on('click', function () {
    $('#header .nav-item').removeClass('down');
    $(this).removeClass('d-block');
  })

  // 浮动留言按钮
  $('#contact-btn').on('click', function () {
    $("#float-contact").slideToggle('fast');
  });
  $('#float-contact .close-btn').on('click', function () {
    $("#float-contact").slideToggle('fast');
  })

  // 反馈信息
  setTimeout(function () {
    $('#message-box').fadeOut('fast', function () {
      $('#message-box').remove();
    })
  }, 5000)
})
